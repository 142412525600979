<template>
   <section id="page-tenants">
      <v-app-bar v-if="activity.step > 1" dark flat elevation="0" color="primary" height="68">
         <v-list-item dark class="primary">
            <v-list-item-avatar v-if="operator.Face" rounded>
               <img :src="setUrlFromImage(operator.Face)" alt="Face" style="object-fit: cover;" @error="() => { operator.Face = '' }">
            </v-list-item-avatar>
            <v-list-item-icon v-else class="my-auto mr-4">
               <v-icon size="56">
                  mdi-account-box
               </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
               <v-list-item-title class="text-h6" v-text="operator.name || 'Anonymous'" />
               <!-- <v-list-item-subtitle v-text="operator.organization || 'Individual'" /> -->
               <v-list-item-subtitle v-text="`${(operator.Organizations.find(j => j.identity === operator.organization) || {}).name} ( ${operator.Floors.map(j => j.name).join(', ')} )`" />
            </v-list-item-content>
         </v-list-item>
         <!-- <v-spacer /> -->
         <v-menu transition="slide-x-reverse-transition">
            <template v-slot:activator="{ on, attrs }">
               <v-btn icon large v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
               </v-btn>
            </template>
            <v-list>
               <template v-for="(menu, idx) in menus">
                  <v-divider v-if="menu.divider" :key="idx" inset class="mx-0" style="max-width: 100%;" />
                  <v-list-item v-else :key="idx" :disabled="menu.disabled" @click="command(menu.value)">
                     <v-list-item-title>{{ menu.text }}</v-list-item-title>
                     <v-list-item-icon class="my-3">
                        <v-icon>{{ menu.icon || 'mdi-help' }}</v-icon>
                     </v-list-item-icon>
                  </v-list-item>
               </template>
            </v-list>
         </v-menu>
      </v-app-bar>
      <v-dialog v-model="dialog.show" fullscreen hide-overlay transition="dialog-bottom-transition">
         <v-card>
            <v-toolbar dark flat elevation="0" color="primary" height="68">
               <v-btn icon dark @click="dialog.show = false">
                  <v-icon>mdi-close</v-icon>
               </v-btn>
               <v-toolbar-title>Profile</v-toolbar-title>
               <v-spacer />
               <v-toolbar-items>
                  <v-btn dark text @click="updateProfile">
                     Save
                  </v-btn>
               </v-toolbar-items>
            </v-toolbar>
            <v-list-item two-line>
               <v-list-item-content>
                  <v-list-item-title class="text-h5" style="letter-spacing: 0 !important;">
                     {{ (operator.Organizations.find(j => j.identity === operator.organization) || {}).name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                     {{ operator.Floors.map(j => j.name).join(', ') }}
                  </v-list-item-subtitle>
               </v-list-item-content>
            </v-list-item>
            <v-card-text>
               <v-form ref="form">
                  <v-row>
                     <v-col cols="12" md="4">
                        <v-text-field v-model="operator.name" label="Your Name" required :rules="[v => !!v]">
                           <template slot="append-outer">
                              <v-btn icon tile @click="$refs.personFace.click()">
                                 <v-avatar v-if="operator.Face" tile size="24">
                                    <img :src="setUrlFromImage(operator.Face)" alt="Face" style="object-fit: cover;" @error="() => { operator.Face = null }">
                                 </v-avatar>
                                 <v-icon v-else>
                                    {{ operator.Face === null ? 'mdi-image-outline' : 'mdi-image' }}
                                 </v-icon>
                                 <input ref="personFace" type="file" accept="image/*" alt="personFace" style="display:none;" @change="attachmentPreview">
                              </v-btn>
                           </template>
                        </v-text-field>
                     </v-col>
                     <v-col cols="12" md="4">
                        <v-text-field v-model="operator.email" label="Email" required :rules="[v => /^\w+([-\+\.]?\w+)*@\w+([-\.]?\w+)*(\.\w{2,4})+$/.test(v)]" />
                     </v-col>
                     <v-col cols="12" md="4">
                        <v-text-field v-model="dialog.password" label="Password" required :rules="[v => !!v]"
                        :type="seen?'text':'password'" :append-icon="seen?'mdi-eye':'mdi-eye-off'"
                        @click:append="seen=!seen"
                        />
                     </v-col>
                  </v-row>
               </v-form>
            </v-card-text>
            <!--
            <v-list three-line subheader>
               <v-subheader>Profile</v-subheader>
               <v-list-item>
                  <v-list-item-content>
                     <v-list-item-title>Picture</v-list-item-title>
                     <v-list-item-subtitle>Set the content filtering level to restrict apps that can be downloaded</v-list-item-subtitle>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item>
                  <v-list-item-content>
                     <v-list-item-title>Password</v-list-item-title>
                     <v-list-item-subtitle>Require password for purchase or use password to restrict purchase</v-list-item-subtitle>
                  </v-list-item-content>
               </v-list-item>
            </v-list>
            <v-divider />
            <v-list three-line subheader>
               <v-subheader>General</v-subheader>
               <v-list-item>
                  <v-list-item-action>
                     <v-checkbox />
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title>Notifications</v-list-item-title>
                     <v-list-item-subtitle>Notify me about updates to apps or games that I downloaded</v-list-item-subtitle>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item>
                  <v-list-item-action>
                     <v-checkbox />
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title>Sound</v-list-item-title>
                     <v-list-item-subtitle>Auto-update apps at any time. Data charges may apply</v-list-item-subtitle>
                  </v-list-item-content>
               </v-list-item>
               <v-list-item>
                  <v-list-item-action>
                     <v-checkbox />
                  </v-list-item-action>
                  <v-list-item-content>
                     <v-list-item-title>Auto-add widgets</v-list-item-title>
                     <v-list-item-subtitle>Automatically add home screen widgets</v-list-item-subtitle>
                  </v-list-item-content>
               </v-list-item>
            </v-list>
            -->
         </v-card>
      </v-dialog>
      <n-login-form v-if="activity.step < 2" :activity="activity" :operator="operator" />
      <n-invitation-form v-if="activity.step === 2" :activity="activity" :operator="operator" @update="updateRecord" />
      <!-- :reasons="reasons" -->
      <n-approval-table v-if="activity.step === 2" :activity="activity" :operator="operator" :records="records" :floors="floors" @update="updateRecord" />
   </section>
</template>

<script>
import { flatten, imageFromUrl, blobImageToBase64, encrypt, decrypt } from '@/utils/nirtara'
import { approval } from '@/utils/fields.js' // , invitation
export default {
   name: 'SectionPageTenants',

   components: {
      NLoginForm: () => import('@/components/tenant/NLoginForm'),
      NInvitationForm: () => import('@/components/tenant/NInvitationForm'),
      NApprovalTable: () => import('@/components/tenant/NApprovalTable'),
   },

   data: () => ({
      isUsed: false,
      operator: { Face: '', online: '', Organizations: [], Floors: [] },
      activity: { step: 0, id: -1 }, // step: 0 | 2
      // activity: {},
      records: [],
      floors: [],
      menus: [],
      dialog: { show: false, password: '' },
      seen: false,
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   watch: {
      'operator.online' (since) {
         // console.log(`watch ( since = ${since} )`)
         if (since) {
            this.selectRecord('Approval')
            // this.$socket.off(`client/${this.operator.zone}/Activity`, this.handleRecord)
            this.$socket.on(`client/${this.operator.zone}/Tenant/${this.operator.organization}`, this.handleRecord)
         }
      },
   },
   created () {
      // this.$waitSetting(zone => this.$socket.on(`client/${this.$store.state.zone}/Activity`, this.handleRecord))
      // this.$socket.on(`client/${this.operator.zone}/Activity`, this.handleRecord)
      this.menus = [
         { text: 'Profile', icon: 'mdi-account-settings', value: 'profile' }, // , disabled: badges.length < 2
         { divider: true },
         { text: 'Logout', icon: 'mdi-logout', value: 'logout' },
      ]
   },
   mounted () {
   },
   destroyed () {
      this.operator.online && this.$socket.off(`client/${this.operator.zone}/Tenant/${this.operator.organization}`, this.handleRecord)
   },
   methods: {
      command (menu) {
         // console.log(`command ( menu = ${menu} )`, JSON.parse(JSON.stringify(this.operator)))
         switch (menu) {
            case 'profile': this.dialog = { show: true, password: decrypt(this.operator.password) }
               break
            case 'logout': this.activity.step = 0
               break
         }
      },
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'personFace': this.operator.Face = base64
                  break
            }
         })
      },
      async selectRecord (kind, skip) {
         !skip && (this.records = [])
         !kind && (kind = this.activity.kind)
         var data = {
            conditions: { kind: kind, 'object.organization': this.operator.organization },
            headers: { command: 'selectActivity' },
            options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         // console.log(`selectRecord (${kind})`, JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else {
            switch (kind) {
               // default: break
               case 'Approval':
                  this.records = response.data.map(json => {
                     const activities = json.Activity[0][json.kind].filter(j => j.Activity)
                     json.Activity[0][json.kind] = json.Activity[0][json.kind].filter(j => !j.Activity)
                     return Object.assign({}, approval, flatten(json), { Activities: activities })
                  })
                  // this.organizations = await this.selectParent({ kind: 'Organization' }, { _id: 0, identity: 1, name: '$object.name', Floors: '$array' }, 'Subject')
                  this.floors = await this.selectParent({ kind: 'Floor' }, { _id: 0, identity: 1, name: '$object.name' }, 'Location')
                  // this.reasons = []
                  // console.log(`selectRecord ( this.floors = ${this.floors.length} )`, JSON.parse(JSON.stringify(this.floors)))
                  break
            }
            // console.log(`selectRecord ( ${kind} = ${this.records.length} )`, JSON.parse(JSON.stringify(this.records)))
         }
      },
      selectParent (conditions, projection, type) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               headers: { command: `select${type ?? 'Person'}` },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      async updateRecord (item) {
         const array = []
         item.Activities && array.push(...item.Activities)
         item.Emails && array.push(...item.Emails)
         const images = []
         item.Card && images.push({ Card: [item.Card] }) // { Card: item.Card ? [`${item.Card.substr(0, 32)}....`] : [] },
         item.Face && images.push({ Face: [item.Face] }) // { Face: item.Face ? [`${item.Face.substr(0, 32)}....`] : [] },

         var data = {
            update: {
               id: typeof item.id === 'string' ? item.id : undefined,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity || '*',
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name, // visitor | organization
                  // below added for approval
                  activity: item.activity,
                  phone: item.phone,
                  email: item.email,
                  organization: item.organization,
                  floor: item.floor,
                  reason: item.reason,
                  message: item.message,
                  valid: item.valid,
                  until: item.until,
                  // below added for invitation
                  // reason: item.reason || item.subject,
                  subject: item.subject,
               },
            },
            headers: { command: item.kind === 'Approval' ? 'updateApproval' : item.kind === 'Invitation' ? 'updateInvitation' : 'updateActivity' },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Activity) {
            this.activity.id = -1
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
         } else if (response.data.kind === 'Normal') {
            this.activity.id = -1
         }
      },
      async updateProfile () {
         if (!this.$refs.form.validate()) return
         const { operator } = this
         // console.log('updateProfile (operator)', JSON.parse(JSON.stringify(operator)))
         const images = []
         operator.Card && images.push({ Card: [operator.Card] }) // { Card: operator.Card ? [`${operator.Card.substr(0, 32)}....`] : [] },
         operator.Face && images.push({ Face: [operator.Face] }) // { Face: operator.Face ? [`${operator.Face.substr(0, 32)}....`] : [] },
         var data = {
            update: {
               id: operator.id,
               kind: operator.kind,
               object: {
                  [operator.kind]: images,
                  name: operator.name,
                  email: operator.email,
                  password: encrypt(this.dialog.password),
               },
            },
            headers: { command: 'updateProfile' },
         }
         // console.log('updateProfile (data)', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateProfile (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Person) {
            const json = response.data
            json.Person[0][json.kind] = json.Person[0][json.kind].filter(j => !(j.Organization || j.Floor))
            Object.assign(operator, flatten(json))
            // console.log('updateProfile (operator)', JSON.parse(JSON.stringify(operator)))
            this.dialog.show = false
         }
      },
      handleRecord (item) {
         // console.log('handleRecord (item)', JSON.parse(JSON.stringify(item)))
         const { records } = this // , activity
         // console.log('handleRecord (activity)', JSON.parse(JSON.stringify(activity)))
         item.Content.forEach(async record => {
            // console.log('handleRecord (record)', JSON.parse(JSON.stringify(record)))
            // if (record.kind !== activity.kind) return // activity.kind -> undefined
            if (!['Approval', 'Invitation'].includes(record.kind)) return
            const idx = records.findIndex(j => j.id === record.id)
            idx >= 0 && records.splice(idx, 1)
            records.splice((60 + 600 * 16) - 1, records.length - 9659) // (60 + 600 * 16) = 9660
            if (['Create', 'Update', 'Import'].includes(item.action)) { //, 'Delete'
               const template = record.kind === 'Approval' ? approval : null // record.kind === 'Invitation' ? invitation :
               if (record.kind === 'Approval') {
                  const activities = record.Activity[0][record.kind].filter(j => j.Activity)
                  record.Activity[0][record.kind] = record.Activity[0][record.kind].filter(j => !j.Activity)
                  records.unshift(Object.assign({}, template, flatten(record), { Activities: activities }))
               } else {
                  records.unshift(Object.assign({}, template, flatten(record)))
               }
            }
         })
      },
   },
}
</script>

<style lang="scss" scoped>
</style>
